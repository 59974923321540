import React from "react";
import Whatsapp from "../images/whatsapp-svgrepo-com (2).png"

export const Work = () => {
  return (
    <div className="work">       
      <a href="https://wa.me/5491176180939?">
      <img src={Whatsapp} className="logo-principal" alt="" />
      </a>
    </div>
  );
};
